.rotating-image-container {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: 150px;
    transform: rotate(-5deg);
    z-index: 2;
  }
  
  .rotating-image {
      animation: rotateImage 5s linear infinite;
      padding-top: 50px;
      overflow: hidden;
    }
  
    @keyframes rotateImage {
      0% {
        transform: translateX(100vw);
      }
      100% {
        transform: translateX(-100%);
      }
    }