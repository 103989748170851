.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 10, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .popup-content {
    position: relative;
    background-color: darkgrey;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-family: 'Glitch', sans-serif;
    color: black;
    text-align: center;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-family: 'Glitch', sans-serif;
    color: black;
    background: none;
    border: none;
    padding: 0;
    font-size: x-large;
    font-weight: bolder;
  }

  @font-face {
    font-family: 'Glitch';
    src: url(./../fonts/GlitchGoblin-2O87v.ttf);
  }