.rotating-banner {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    transform: rotate(-5deg);
  }

  .banner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 5px solid darkred;
    padding: 1.5vh;
    background-color: grey;
    text-align: center;
  }
  
  .rotating-text {
    position: absolute;
    font-family: 'Glitch', sans-serif;
    color: black;
    font-weight: bold;
    left: 0;
    right: 0;
    white-space: nowrap;
    animation: rotateText 5s linear infinite;
    transform: rotate(-5deg);
  }

  @font-face {
    font-family: 'Glitch';
    src: url(./../fonts/GlitchGoblin-2O87v.ttf);
  }
  
  
  @keyframes rotateText {
    0% {
      transform: translateX(0%);
    }
    99% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .rotating-text span {
    display: inline-block;
    padding-right: 20px;
  }
  