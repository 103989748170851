.App {
  background-color: darkslategrey;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

@font-face {
  font-family: 'Glitch';
  src: url(./fonts/GlitchGoblin-2O87v.ttf);
}

.App-header {
  margin-top: 150px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 120px;
}

.imgHug {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(0%, -0%) translate(135%, -110%);
  max-width: 150px;
}

.buy-now-btn {
  margin: 5px; 
  padding: 15px 30px; 
  font-size: 18px;
  color: black;
  border: none;
  cursor: pointer;
  border: 10px solid darkred;
  border-radius: 20px;
  cursor: pointer;
  position: relative;
  font-family: 'Glitch', sans-serif;
  background-color: grey;
  font-weight: bolder;
}

.text-container {
  display: flex;
  font-family: "Glitch", sans-serif;
  color:black ;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.text-container-2 {
  display: flex;
  font-family: "Glitch", sans-serif;
  color:black ;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.text-container-3 {
  display: flex;
  font-family: "Glitch", sans-serif;
  color:black ;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.icons-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding-top: 100px;
  position: relative ;
  bottom: -20px;
  left: 50%; 
  transform: translateX(-50%); 
  max-width: 300px;
}

.socialIcon {
  margin-right: 20px;
}

.clickable-icon {
  margin-right: 20px;
}

